<template>
  <div class="description panel blue">
    <div><h1>Scrub animations</h1>
      <p>A ScrollTrigger with <code>scrub: true</code> links the scroll position to an animation's progress, making the
        scroll bar act like a scrubber while it's between the ScrollTrigger's <code>start</code> and <code>end</code>.
      </p>
      <div class="scroll-down">Scroll down
        <div class="arrow"></div>
      </div>
    </div>
  </div>

  <div class="massiveImage">
  </div>
  <section class="panel orange">
    <p><span class="line line-2"></span>This orange panel gets pinned when its top edge hits the top of the viewport,
      then the line's animation is linked with the scroll position until it has traveled 100% of the viewport's height (<code>end:
        "+=100%"</code>), then the orange panel is unpinned and normal scrolling resumes. Padding is added automatically
      to push the rest of the content down so that it catches up with the scroll when it unpins. You can set <code>pinSpacing:
        false</code> to prevent that if you prefer.</p>
  </section>

  <section class="panel red red-one">
    <h2>Most watched</h2>
    <h3>club by league</h3>
    <div class="img-container">
      <img class="feature-image feature-image--support-player-1" id="parallax4-child"
           src="https://www.mann.tv/thumbs/article_picture/2021/w33/meganfox-breit.jpg" alt="">
      <img class="feature-image feature-image--support-player-2" id="parallax4-child-3"
           src="" alt="">
      <img class="feature-image feature-image--support-player-3" id="parallax4-child-2"
           src="https://img.discogs.com/_7eO8nvku_rn2JI4dnTjcX8XE44=/600x337/smart/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/A-4223409-1617531646-2295.jpeg.jpg" alt="">
    </div>
  </section>

  <!-- <p class="p1">This line's animation will begin when it enters the viewport and finish when its top edge hits the top of the viewport, staying perfectly in sync with the scrollbar because it has <code>scrub:&nbsp;true</code></p>
  <p class="p2">This line's animation will begin when it enters the viewport and finish when its top edge hits the top of the viewport, staying perfectly in sync with the scrollbar because it has <code>scrub:&nbsp;true</code></p>
</section> -->


  <section class="panel purple">
    <p><span class="line line-3"></span>This panel gets pinned in a similar way, and has a more involved animation
      that's wrapped in a timeline, fading the background color and animating the transforms of the paragraph in
      addition to the line, all synced with the scroll position perfectly.</p>
  </section>


  <section class="panel red red-two">
    <p class="p1">This line's animation will begin when it enters the viewport and finish when its top edge hits the top
      of the viewport, staying perfectly in sync with the scrollbar because it has <code>scrub:&nbsp;true</code></p>
    <p class="p2">This line's animation will begin when it enters the viewport and finish when its top edge hits the top
      of the viewport, staying perfectly in sync with the scrollbar because it has <code>scrub:&nbsp;true</code></p>
  </section>


  <section>
    <div class="cInnerContent">
      <h1 class="header-section gs_reveal ipsType_center">Scroll down and up to see different reveal animations</h1>

      <div class="features">

        <div class="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
          <div class="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromLeft">
            <div class="card">
              <img width="479" src="https://picsum.photos/479/479?index=1" alt="">
            </div>
          </div>

          <div class="ipsGrid_span7 ipsType_left">
            <h2 class="heading_large gs_reveal">Create amazing <strong>SVG</strong> animations</h2>
            <p class="gs_reveal"><a href="https://greensock.com/drawsvg/">DrawSVGPlugin</a> allows you to progressively
              reveal (or hide) SVG strokes to make them look like they're being drawn. <a
                  href="https://greensock.com/morphsvg/">MorphSVGPlugin</a> to Morph any SVG shape into any other shape
              smoothly regardless of the number of points in each. <a href="/docs/v3/Plugins/MotionPathPlugin">MotionPathPlugin</a>
              allows you to easily move any object along a path.</p>
          </div>
        </div>

        <div class="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
          <div class="ipsGrid_span7 ipsType_right">
            <h2 class="heading_large gs_reveal">Supercharge immersive <strong>WebGL</strong> experiences</h2>
            <p class="gs_reveal">GreenSock is used in some of the most popular <a href="//threejs.org/">Three.js</a>
              powered WebGL projects. Animate any object in a scene. <a href="https://greensock.com/PixiPlugin/">PixiPlugin</a>
              makes animating <a href="//www.pixijs.com/">Pixi.js</a> objects with GSAP a breeze. Animate position,
              scale, color effects and more with all the power and control of GSAP and the rendering speed of Pixi.js.
            </p>
          </div>

          <div class="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromRight">
            <div class="card">
              <img width="479" src="https://picsum.photos/479/479?index=2" alt="">
            </div>
          </div>
        </div>

        <div class="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
          <div class="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromLeft">
            <div class="card">
              <img width="479" src="https://picsum.photos/479/479?index=3" alt="">
            </div>
          </div>

          <div class="ipsGrid_span7 ipsType_left">
            <h2 class="heading_large gs_reveal">Control performant <strong>Canvas</strong> animations</h2>
            <p class="gs_reveal">GSAP makes animating with Canvas even easier by providing an easier way to look and
              sequence animations. GSAP works great with <a href="//www.adobe.com/products/animate.html">Adobe
                Animate</a> and <a href="//createjs.com/easeljs">EaseJS</a> through GSAP's <a
                  href="https://greensock.com/easelplugin/">EaselJSPlugin</a>.</p>
          </div>
        </div>

        <div class="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
          <div class="ipsGrid_span7 ipsType_right">
            <h2 class="heading_large gs_reveal"><strong>Award winning</strong> websites</h2>
            <p class="gs_reveal">GSAP is used on over 8,500,000 sites and over 1,000 sites featured on <a
                href="https://www.awwwards.com/websites/gsap-animation/" target="_blank">Awwwards</a>. For some of our
              favorites, check out <a href="https://greensock.com/showcase/">our showcase</a>.</p>
          </div>

          <div class="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromRight">
            <div class="card">
              <img width="479" src="https://picsum.photos/479/479?index=4" alt="">
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>


  <section class="panel gray">
    DONE!
  </section>
</template>

<script>

import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);


export default {
  name: "ScrollTriggerNr1",

  mounted() {

    gsap.set(".massiveImage", {backgroundImage: `url(https://source.unsplash.com/random/${innerWidth * 3}x${innerHeight})`})

    gsap.to(".massiveImage", {
      xPercent: -100,
      x: innerWidth,
      ease: "none",
      scrollTrigger: {
        trigger: ".massiveImage",
        start: "top top",
        end: () => innerWidth * 3,
        scrub: true,
        pin: true,
        anticipatePin: 1
      }
    });


//scrub animations: https://codepen.io/GreenSock/pen/WNvVOWw

// --- ORANGE PANEL ---

    gsap.set(".orange", {height: `${innerHeight}`})

    gsap.from(".line-2", {
      scrollTrigger: {
        trigger: ".orange",
        scrub: true,
        pin: true,
        start: "top top",
        end: "+=100%"
      },
      scaleX: 0,
      transformOrigin: "left center",
      ease: "none"
    });


// --- RED PANEL ---

    gsap.set(".img-container", {height: `${innerHeight}`})

    gsap.from(".img-container", {
      scrollTrigger: {
        trigger: ".redone",
        scrub: true,
        pin: true,
        start: "top top",
        end: "+=100%"
      },
      scaleX: 0,
      transformOrigin: "left center",
      ease: "none"
    });

    var tlb = gsap.timeline({
      scrollTrigger: {
        trigger: ".red-one",
        scrub: true,
        pin: true,
        start: "top top",
        end: "+=100%"
      }
    });

    tlb.from(".red-one h2", {x: 200, duration: 3, autoAlpha: 0, ease: "power2"})
        .from(".red-one h3", {x: 200, autoAlpha: 0, delay: 3, ease: "power2"})
        .from(".red-one .feature-image--support-player-1", {
          y: 200,
          duration: 3,
          autoAlpha: 0,
          delay: 2,
          ease: "expo",
          overwrite: "auto"
        })
        .from(".red-one .feature-image--support-player-2", {
          y: -200,
          duration: 2,
          autoAlpha: 0,
          delay: 3,
          ease: "expo",
          overwrite: "auto"
        })
        .from(".red-one .feature-image--support-player-3", {
          scale: 0.1,
          duration: 5,
          autoAlpha: 0,
          delay: 4,
          ease: "expo",
          overwrite: "auto"
        })


// --- PURPLE/GREEN PANEL ---

    gsap.set(".purple", {height: `${innerHeight}`})

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".purple",
        scrub: true,
        pin: true,
        start: "top top",
        end: "+=100%"
      }
    });

    tl.from(".purple p", {scale: 0.3, rotation: 45, autoAlpha: 0, ease: "power2"})
        .from(".line-3", {scaleX: 0, transformOrigin: "left center", ease: "none"}, 0)
        .to(".purple", {backgroundColor: "#28a92b"}, 0);


    gsap.set(".red-two", {height: `${innerHeight}`})


    var tla = gsap.timeline({
      scrollTrigger: {
        trigger: ".red-two",
        scrub: true,
        pin: true,
        start: "top top",
        end: "+=100%"
      }
    });

    tla.from(".red-two .p1", {scale: 0.3, autoAlpha: 0, ease: "power2"})
        .from(".red-two .p2", {scale: 0.3, autoAlpha: 0, delay: 1, ease: "power2"})


// reveal animations https://codepen.io/GreenSock/pen/pojzxwZ
    function animateFrom(elem, direction) {
      direction = direction | 1;

      var x = 0,
          y = direction * 100;
      if (elem.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if (elem.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      }
      gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 2.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto"
      });
    }

    function hide(elem) {
      gsap.set(elem, {autoAlpha: 0});
    }

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFrom(elem)
        },
        onEnterBack: function () {
          animateFrom(elem, -1)
        },
        onLeave: function () {
          hide(elem)
        } // assure that the element is hidden when scrolled into view
      });
    });

  }
}
</script>

<style scoped>


#nav {
  display: none;

}

.line {
  width: 100%;
  max-width: 800px;
  height: 8px;
  margin: 0 0 10px 0;
  position: relative;
  display: inline-block;
  background-color: rgba(255, 255, 255, 1);
}

.panel.align-top {
  align-items: flex-start;
}

.panel h1 {
  font-size: 1.8em;
  color: white;
  font-weight: 300;
  margin: 0 auto;
}

.panel.description {
  padding-bottom: 60px;
}

.panel p, .panel li {
  color: black;
  font-weight: 400;
  text-align: left;
  font-size: 0.8em;
  line-height: 1.5em;
  margin: 0.3em 0 1em 0;
}

.panel p strong, .panel li strong {
  color: white;
  font-weight: 400;
}

.panel p code, .panel li code {
  background-color: rgba(255, 255, 255, 0.15);
  padding: 2px 4px;
  border-radius: 5px;
}

.panel li {
  margin: 0;
}

.gray {
  background-color: #777;
  background-image: radial-gradient(circle at 47% 14%, rgba(205, 205, 205, 0.04) 0%, rgba(205, 205, 205, 0.04) 43%, transparent 43%, transparent 100%), radial-gradient(circle at 35% 12%, rgba(215, 215, 215, 0.04) 0%, rgba(215, 215, 215, 0.04) 4%, transparent 4%, transparent 100%), radial-gradient(circle at 1% 35%, rgba(24, 24, 24, 0.04) 0%, rgba(24, 24, 24, 0.04) 37%, transparent 37%, transparent 100%), radial-gradient(circle at 21% 1%, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.04) 26%, transparent 26%, transparent 100%), radial-gradient(circle at 23% 82%, rgba(249, 249, 249, 0.04) 0%, rgba(249, 249, 249, 0.04) 60%, transparent 60%, transparent 100%), radial-gradient(circle at 11% 54%, rgba(251, 251, 251, 0.04) 0%, rgba(251, 251, 251, 0.04) 23%, transparent 23%, transparent 100%), radial-gradient(circle at 69% 68%, rgba(234, 234, 234, 0.04) 0%, rgba(234, 234, 234, 0.04) 10%, transparent 10%, transparent 100%), linear-gradient(90deg, #777, #606060);
}

.blue {
  background-color: #2c7ad2;
  background-image: radial-gradient(circle at 47% 14%, rgba(205, 205, 205, 0.04) 0%, rgba(205, 205, 205, 0.04) 43%, transparent 43%, transparent 100%), radial-gradient(circle at 35% 12%, rgba(215, 215, 215, 0.04) 0%, rgba(215, 215, 215, 0.04) 4%, transparent 4%, transparent 100%), radial-gradient(circle at 1% 35%, rgba(24, 24, 24, 0.04) 0%, rgba(24, 24, 24, 0.04) 37%, transparent 37%, transparent 100%), radial-gradient(circle at 21% 1%, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.04) 26%, transparent 26%, transparent 100%), radial-gradient(circle at 23% 82%, rgba(249, 249, 249, 0.04) 0%, rgba(249, 249, 249, 0.04) 60%, transparent 60%, transparent 100%), radial-gradient(circle at 11% 54%, rgba(251, 251, 251, 0.04) 0%, rgba(251, 251, 251, 0.04) 23%, transparent 23%, transparent 100%), radial-gradient(circle at 69% 68%, rgba(234, 234, 234, 0.04) 0%, rgba(234, 234, 234, 0.04) 10%, transparent 10%, transparent 100%), linear-gradient(90deg, #2c7ad2, #1568c6);
}

.orange {
  background-color: #e77614;
  background-image: radial-gradient(circle at 46% 40%, rgba(228, 228, 228, 0.06) 0%, rgba(228, 228, 228, 0.06) 13%, transparent 13%, transparent 100%), radial-gradient(circle at 11% 41%, rgba(198, 198, 198, 0.06) 0%, rgba(198, 198, 198, 0.06) 19%, transparent 19%, transparent 100%), radial-gradient(circle at 52% 23%, rgba(14, 14, 14, 0.06) 0%, rgba(14, 14, 14, 0.06) 69%, transparent 69%, transparent 100%), radial-gradient(circle at 13% 85%, rgba(148, 148, 148, 0.06) 0%, rgba(148, 148, 148, 0.06) 44%, transparent 44%, transparent 100%), radial-gradient(circle at 57% 74%, rgba(232, 232, 232, 0.06) 0%, rgba(232, 232, 232, 0.06) 21%, transparent 21%, transparent 100%), radial-gradient(circle at 59% 54%, rgba(39, 39, 39, 0.06) 0%, rgba(39, 39, 39, 0.06) 49%, transparent 49%, transparent 100%), radial-gradient(circle at 98% 38%, rgba(157, 157, 157, 0.06) 0%, rgba(157, 157, 157, 0.06) 24%, transparent 24%, transparent 100%), radial-gradient(circle at 8% 6%, rgba(60, 60, 60, 0.06) 0%, rgba(60, 60, 60, 0.06) 12%, transparent 12%, transparent 100%), linear-gradient(90deg, #ff7600, #ff7600);
}

.red {
  background-color: #c82736;
  background-image: radial-gradient(circle at 19% 90%, rgba(190, 190, 190, 0.04) 0%, rgba(190, 190, 190, 0.04) 17%, transparent 17%, transparent 100%), radial-gradient(circle at 73% 2%, rgba(78, 78, 78, 0.04) 0%, rgba(78, 78, 78, 0.04) 94%, transparent 94%, transparent 100%), radial-gradient(circle at 45% 2%, rgba(18, 18, 18, 0.04) 0%, rgba(18, 18, 18, 0.04) 55%, transparent 55%, transparent 100%), radial-gradient(circle at 76% 60%, rgba(110, 110, 110, 0.04) 0%, rgba(110, 110, 110, 0.04) 34%, transparent 34%, transparent 100%), radial-gradient(circle at 68% 56%, rgba(246, 246, 246, 0.04) 0%, rgba(246, 246, 246, 0.04) 16%, transparent 16%, transparent 100%), radial-gradient(circle at 71% 42%, rgba(156, 156, 156, 0.04) 0%, rgba(156, 156, 156, 0.04) 47%, transparent 47%, transparent 100%), radial-gradient(circle at 46% 82%, rgba(247, 247, 247, 0.04) 0%, rgba(247, 247, 247, 0.04) 39%, transparent 39%, transparent 100%), radial-gradient(circle at 50% 47%, rgba(209, 209, 209, 0.04) 0%, rgba(209, 209, 209, 0.04) 45%, transparent 45%, transparent 100%), linear-gradient(90deg, #e53949, #cc2232);
}

.purple {
  background-color: #8d3dae;
  background-image: radial-gradient(circle at 47% 14%, rgba(205, 205, 205, 0.04) 0%, rgba(205, 205, 205, 0.04) 43%, transparent 43%, transparent 100%), radial-gradient(circle at 35% 12%, rgba(215, 215, 215, 0.04) 0%, rgba(215, 215, 215, 0.04) 4%, transparent 4%, transparent 100%), radial-gradient(circle at 1% 35%, rgba(24, 24, 24, 0.04) 0%, rgba(24, 24, 24, 0.04) 37%, transparent 37%, transparent 100%), radial-gradient(circle at 21% 1%, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.04) 26%, transparent 26%, transparent 100%), radial-gradient(circle at 23% 82%, rgba(249, 249, 249, 0.04) 0%, rgba(249, 249, 249, 0.04) 60%, transparent 60%, transparent 100%), radial-gradient(circle at 11% 54%, rgba(251, 251, 251, 0.04) 0%, rgba(251, 251, 251, 0.04) 23%, transparent 23%, transparent 100%), radial-gradient(circle at 69% 68%, rgba(234, 234, 234, 0.04) 0%, rgba(234, 234, 234, 0.04) 10%, transparent 10%, transparent 100%), linear-gradient(90deg, #8d3dae, #8d3dae);
}

.green {
  background-color: #28a92b;
  background-image: radial-gradient(circle at 46% 40%, rgba(228, 228, 228, 0.06) 0%, rgba(228, 228, 228, 0.06) 13%, transparent 13%, transparent 100%), radial-gradient(circle at 11% 41%, rgba(198, 198, 198, 0.06) 0%, rgba(198, 198, 198, 0.06) 19%, transparent 19%, transparent 100%), radial-gradient(circle at 52% 23%, rgba(14, 14, 14, 0.06) 0%, rgba(14, 14, 14, 0.06) 69%, transparent 69%, transparent 100%), radial-gradient(circle at 13% 85%, rgba(148, 148, 148, 0.06) 0%, rgba(148, 148, 148, 0.06) 44%, transparent 44%, transparent 100%), radial-gradient(circle at 57% 74%, rgba(232, 232, 232, 0.06) 0%, rgba(232, 232, 232, 0.06) 21%, transparent 21%, transparent 100%), radial-gradient(circle at 59% 54%, rgba(39, 39, 39, 0.06) 0%, rgba(39, 39, 39, 0.06) 49%, transparent 49%, transparent 100%), radial-gradient(circle at 98% 38%, rgba(157, 157, 157, 0.06) 0%, rgba(157, 157, 157, 0.06) 24%, transparent 24%, transparent 100%), radial-gradient(circle at 8% 6%, rgba(60, 60, 60, 0.06) 0%, rgba(60, 60, 60, 0.06) 12%, transparent 12%, transparent 100%), linear-gradient(90deg, #28a92b, #10a614);
}

html, body {
  margin: 0;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  font-family: "Signika Negative", sans-serif, Arial;
}

.panel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  color: white;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

/* HEADER */
header {
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 6px 10px 10px 10px;
  border-bottom-right-radius: 26px;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

/* HEADINGS */
.header-section {
  padding: 0 15px;
  text-align: center;
  margin: 40vh auto 50vh;
}

/* SCROLL DOWN */
.scroll-down {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  color: black;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
  overflow: visible;
}

.scroll-down .arrow {
  position: relative;
  top: -4px;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4KPHBhdGggZmlsbD0iYmxhY2siIGQ9Ik00ODMuMiwxOTIuMmMtMjAuNS0yMC41LTUzLjUtMjAuOC03My43LTAuNkwyNTcsMzQ0LjFMMTA0LjUsMTkxLjZjLTIwLjItMjAuMi01My4yLTE5LjktNzMuNywwLjYKCWMtMjAuNSwyMC41LTIwLjgsNTMuNS0wLjYsNzMuN2wxOTAsMTkwYzEwLjEsMTAuMSwyMy40LDE1LjEsMzYuOCwxNWMxMy4zLDAuMSwyNi43LTQuOSwzNi44LTE1bDE5MC0xOTAKCUM1MDMuOSwyNDUuNyw1MDMuNywyMTIuNyw0ODMuMiwxOTIuMnoiLz4KPC9zdmc+);
  background-size: contain;
}

.massiveImage {
  height: 100vh;
  width: 400vw;
  background-size: cover;
  background-position: center;
}

.ipsType_right {
  text-align: right;
}

.ipsType_center {
  text-align: center;
}

.cInnerContent {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}

.ipsSpacer_bottom_double {
  margin-bottom: 30px;
}

.ipsGrid {
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.ipsGrid::before, .ipsGrid::after {
  display: table;
  content: "";
  line-height: 0;
}

.ipsGrid > [class*="ipsGrid_span"] {
  display: block;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}

.ipsGrid > .ipsGrid_span5 {
  width: 40.42553191489362%;
}

.ipsGrid > .ipsGrid_span7 {
  width: 57.44680851063829%;
}

.ipsGrid > [class*="ipsGrid_span"] {
  float: left;
  margin-left: 2%;
}

.ipsGrid > [class*="ipsGrid_span"]:first-child {
  margin-left: 0;
}

.feature {
  display: flex;
  align-items: center;
}

.card {
  margin-bottom: 30px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 1px 1px 5px 1px #CCCCCC;
  transition: 0.3s;
}

.featured-image-container .card {
  padding: 10px;
  height: 0;
  padding-bottom: calc(100% - 10px);
}

h2.heading_large {
  font-size: 1.8em;
}

img {
  max-width: 100%;
}

.header-section {
  margin: 200px auto;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  transition: transform 0s;
}
</style>